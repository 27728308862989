<template>
  <div class="child-edit">
    <!-- 基本信息 -->
    <div class="info">
      <div class="row">
        <span class="label">测评宝宝姓名</span>
        <span class="value">{{ customer.childrenName }}</span>
      </div>
      <div class="row">
        <span class="label">测评宝宝年龄</span>
        <span class="value">{{ customer.childrenBirthday }}{{ assessAge?`（${assessAge}）`:'-' }}</span>
      </div>
      <div class="row">
        <span class="label">测评宝宝性别</span>
        <span class="value">{{ customer.childrenSex|sexConvert }}</span>
      </div>
      <div class="row">
        <span class="label">测评时间</span>
        <span class="value">{{ curTime }}</span>
      </div>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      class="form"
    >
      <!-- content 1 -->
      <div class="content">
        <el-form-item
          label="近日测量身长/身高"
          prop="height"
        >
          <el-input
            v-model="form.height"
            v-inputValid="/[^\d\.]/g"
            type="text"
            placeholder="请输入"
            autocomplete="off"
          >
            <span slot="suffix">cm</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="近日测量体重"
          prop="weight"
        >
          <el-input
            v-model="form.weight"
            v-inputValid="/[^\d\.]/g"
            type="text"
            placeholder="请输入"
            autocomplete="off"
          >
            <span slot="suffix">kg</span>
          </el-input>
        </el-form-item>

        <!-- <el-form-item
          v-if="customer.expertReport==0||!customer.expertReport"
          prop="expertReportState"
        >
          <el-checkbox
            v-model="form.expertReportState"
            true-label="APPLIED"
            false-label="NOT_APPLIED"
          >
            申请专家报告
          </el-checkbox>
        </el-form-item> -->
      </div>
    </el-form>

    <div class="text-right">
      <el-button
        round
        class="linear-btn next"
        @click="submit"
      >
        下一步
      </el-button>
    </div>
  </div>
</template>

<script>
import { getCustomerInfo, createRecord } from '@/api';
import { getAge, dateFormat } from '@/utils/utils';

export default {

  filters: {
    sexConvert(value) {
      const sexMap = {
        0: '未知',
        1: '男',
        2: '女'
      };
      return sexMap[value] || '-';
    },
  },
  data() {
    return {
      form: {
        height: '',
        weight: '',
        expertReportState: 'NOT_APPLIED'
      },
      rules: {
        height: [{ required: true, message: '请输入宝宝身高', trigger: 'blur' }],
        weight: [{ required: true, message: '请输入宝宝体重', trigger: 'blur' }]
      },
      isSubmit: false,
      customer: {}
    };
  },
  computed: {
    // 计算年龄
    assessAge() {
      if (!this.customer.childrenBirthday) return '';
      return getAge(this.customer.childrenBirthday);
    },
    curTime() {
      return dateFormat('YYYY-mm-dd', new Date());
    },
    id() {
      return Number(this.$route.query.id);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getCustomerInfo(this.id);
    },
    getCustomerInfo(id) {
      const params = {
        id
      };
      getCustomerInfo(params).then((res) => {
        this.customer = res?.data || {};
        // this.form.height = this.customer.height;
        // this.form.weight = this.customer.bodyWeight;
        this.form.expertReportState = Number(this.customer.expertReport) === 1 ? 'APPLIED' : 'NOT_APPLIED';
      }, (err) => {
        this.customer = {};
      });
    },
    submit() {
      this.isSubmit = true;
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.isSubmit = false;
          return;
        }
        this.createRecord();
      });
    },
    createRecord() {
      const params = {
        customerId: this.id,
        ...this.form,
      };
      createRecord(params).then((res) => {
        this.$router.replace({
          name: 'eval.question',
          query: {
            id: this.id,
            evalRecordId: res?.data?.id,
            childrenSex: this.customer?.childrenSex,
            childrenBirthday: this.customer?.childrenBirthday
          }
        });
      }, (err) => {
        this.$message.warning(err.msg || '创建测评失败！');
      });
    }
  },
};
</script>

<style lang="less" scoped>
.child-edit{
  padding-top: 16px;
  margin: 0 auto;
  width: 500px;
}
.info{
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 20px;
  .row{
    margin-bottom: 16px;
  }
  .label{
    width: 120px;
    display: inline-block;
    font-weight: 600;
  }
}
.form{
  margin-top: 16px;
}
/deep/ .el-form-item{
  margin: 0 auto;
  width: 500px;
  padding-bottom: 16px;
}
/deep/.el-date-editor.el-input{
  width: 100%;
}
/deep/.el-form--label-top .el-form-item__label{
  line-height: 1;
}
/deep/.el-form-item__label,/deep/.el-input__inner{
  color:#333;
}
/deep/.el-form-item__error{
  top: -24px;
  left: unset;
  right: 0;
}
</style>
